import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Box, Modal } from '@mui/material';
import ContactForm from '../../components/ContactUs/ContactForm';

const PublicationsContent = () => {
    const { publicationName } = useParams();

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            {/* Header */}
            <Header />

            {/* Main Content */}
            <div className="flex-grow p-4">
                {
                    publicationName === "bullet-in-points" && <BulletIn />
                }
                {
                    publicationName === "member-bios" && <MemberBios />
                }
                {
                    publicationName === "capitol-recap" && <CapitolRecap />
                }
                {
                    publicationName === "how-a-bill-becomes-a-law" && <LegislativeGuide />
                }
                {
                    publicationName === "industry-recaps" && <IndustryRecap />
                }

            </div>

            {/* Footer */}
            <Footer />
        </div>
    );
};

const CapitolRecap = () => {
    return (
        <div className="bg-white p-6 max-w-xl mx-auto rounded-md shadow-md">
            <h2 className="text-2xl font-bold mb-4">Capitol Recap</h2>
            <p className="text-gray-700">
                Stay ahead of the curve with our Capitol Recap, a daily comprehensive report that keeps you informed of all key legislative, regulatory, and executive actions.
                This service includes detailed coverage of floor votes, committee meetings, and public hearings, ensuring you never miss critical updates. Delivered directly to your
                inbox every day, the report provides timely insights unless no actions have taken place, allowing you to focus on what matters most without unnecessary clutter.
                Empower your decision-making with the most current information at your fingertips.
            </p>
        </div>
    );
};

const LegislativeGuide = () => {
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => {
        setIsOpen(!isOpen)
    }
    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "40%",
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    pt: 2,
                    px: 4,
                    pb: 3,
                }}>
                    <ContactForm email={"info@mypls.com"} onClose={handleClose}/>
                </Box>
            </Modal>
            <h2 className="text-2xl font-bold mb-4">
                Unlock the Secrets of Pennsylvania’s Legislative Process in One Easy-to-Understand Document
            </h2>
            <p className="mb-4 text-gray-700">
                Navigating Pennsylvania’s legislative process can be daunting, but our
                <span className="font-semibold"> expertly crafted 2-page guide</span> breaks it all down for you in a clear, concise, and easy-to-follow format.
                Whether you’re a seasoned government relations professional, a policy advocate, or simply looking to better understand how laws are made in Pennsylvania, this PDF is your go-to resource.
            </p>

            <h3 className="text-xl font-semibold mb-4">Why You Need This Guide:</h3>
            <ul className="list-disc list-inside mb-6">
                <li className="mb-2">
                    <span className="font-semibold">Comprehensive and Simplified:</span> We’ve taken the complexity of the legislative process and distilled it into a simple, step-by-step explanation—from bill introduction to final passage.
                </li>
                <li className="mb-2">
                    <span className="font-semibold">Save Time and Eliminate Confusion:</span> Stop wasting hours trying to piece together the process. This document gives you everything you need in one easy-to-digest page.
                </li>
                <li className="mb-2">
                    <span className="font-semibold">Expert Insights:</span> Written by professionals with years of experience in Pennsylvania government, this guide is a trusted and accurate resource you can rely on.
                </li>
            </ul>

            <p className="mb-4 text-gray-700">
                <span className="font-semibold">Instant Access in PDF Format:</span> Conveniently download the document and have it at your fingertips whenever you need it. Whether you’re preparing for a meeting or just brushing up on your knowledge, this guide is an essential tool.
            </p>

            <h3 className="text-xl font-semibold mb-4">Get Your Copy Now</h3>
            <p className="mb-4 text-gray-700">
                Master the legislative process today and gain a competitive edge.{" "}
                <span onClick={handleClose} className="text-blue-600 underline cursor-pointer"> Contact us</span> to purchase and download your
                <span className="font-semibold"> 2-page PDF guide</span> and make the Pennsylvania legislative system work for you!
            </p>
        </div>
    );
};

const IndustryRecap = () => {
    return (
        <div className="bg-white p-6 max-w-2xl mx-auto rounded-md shadow-md">
            <h2 className="text-2xl font-bold mb-4">
                Industry Recaps - In-Depth Coverage of Pennsylvania's Key Sectors
            </h2>
            <p className="text-gray-700 mb-4">
                Stay informed and ahead of the curve with <strong>PLS’s Industry Recaps</strong>, your go-to source for comprehensive insights into Pennsylvania's legislative and government items and events.
                Whether you're interested in education, healthcare, or transportation, we offer specialized reports that keep you updated on the latest legislative developments.
            </p>

            <h3 className="text-lg font-semibold mb-2">Choose Your Recap:</h3>
            <ul className="list-disc list-inside text-gray-700 mb-4 space-y-2">
                <li>
                    <strong>Commonwealth Recap Bundle (Education, Health Care, and Transportation):</strong> Get the complete picture with PLS’s detailed coverage of Pennsylvania's legislative and government events. This bundle includes all three industry-specific recaps, providing a thorough overview of key sectors around the Capitol.
                </li>
                <li>
                    <strong>PA Education Recap:</strong> Stay informed on Pennsylvania’s education industry with our weekly report. Keep track of legislative changes, policy updates, and key events impacting education across the state.
                </li>
                <li>
                    <strong>PA Health Care Recap:</strong> Receive a weekly overview of Pennsylvania’s healthcare sector, including detailed summaries of the monthly Medical Assistance Advisory Committee (MAAC) meetings. Stay on top of the issues that matter most to healthcare professionals and stakeholders.
                </li>
                <li>
                    <strong>PA Transportation Recap:</strong> Keep pace with Pennsylvania’s transportation industry with our weekly report. From legislative updates to key developments, this recap ensures you're always in the know.
                </li>
            </ul>

            <h3 className="text-lg font-semibold mb-2">Subscribe Today:</h3>
            <p className="text-gray-700">
                Access these essential insights by subscribing to PLS’s Industry Recaps and stay connected to the heartbeat of Pennsylvania’s state government.
            </p>
        </div>
    );
};

const BulletIn = () => {
    return (
        <div className="bg-white p-6 max-w-2xl mx-auto rounded-md shadow-md">
            <h2 className="text-2xl font-bold mb-4">
                Key Insights from the <em>Pennsylvania Bulletin</em>: Essential for Your Business
            </h2>
            <p className="text-gray-700 mb-4">
                Stay ahead of the game with timely updates from the <em>Pennsylvania Bulletin</em>. Here’s why it’s essential for your business:
            </p>

            <ul className="list-disc list-inside text-gray-700 mb-4 space-y-2">
                <li>
                    <strong>Regulatory Changes:</strong> Keep informed about new or updated state regulations that could impact your operations.
                </li>
                <li>
                    <strong>Public Notices & Hearings:</strong> Don’t miss important opportunities like public hearings, contracts, or grant announcements.
                </li>
                <li>
                    <strong>Executive Orders:</strong> Stay compliant by following directives from the Governor’s office.
                </li>
                <li>
                    <strong>Licensing & Professional Actions:</strong> Protect your business by tracking professional licensing changes and disciplinary actions.
                </li>
                <li>
                    <strong>Environmental Notices:</strong> Ensure compliance with air, water, and environmental permits relevant to your business.
                </li>
            </ul>

            <p className="text-gray-700">
                Don’t let regulatory changes catch you off guard—use the Pennsylvania Bulletin to stay informed and proactive.
            </p>
        </div>
    );
};

const MemberBios = () => {
    return (
        <div className="bg-white p-6 max-w-2xl mx-auto rounded-md shadow-md">
            <h2 className="text-2xl font-bold mb-4">
                Maximize Your Legislative Impact with PLS’s Member Bios Service
            </h2>
            <p className="text-gray-700 mb-4">
                Preparing for meetings with legislators just got easier. PLS’s <strong>Member Bios</strong> service provides you with critical insights into legislators’ backgrounds, committee assignments, and district representation—giving you the edge when discussing important legislative agendas.
            </p>

            <h3 className="text-lg font-semibold mb-2">Here’s how <strong>Member Bios</strong> can benefit you:</h3>
            <ul className="list-disc list-inside text-gray-700 mb-4 space-y-2">
                <li>
                    <strong>Targeted Search:</strong> Quickly generate comprehensive bios for individual legislators, including key details about their district, party affiliation, contact info, committees, and a general overview of their political background and priorities.
                </li>
                <li>
                    <strong>Committee Overview:</strong> Search by committee to generate bios for all members, ensuring you can focus your discussions on relevant legislative issues with the right decision-makers.
                </li>
                <li>
                    <strong>District Knowledge:</strong> Gain insights into legislators’ home districts, who they represent, and the key issues important to their constituents.
                </li>
            </ul>

            <p className="text-gray-700 mb-4">
                Whether you’re preparing for a meeting or equipping your clients with key legislative knowledge, PLS’s <strong>Member Bios</strong> service is an invaluable resource for staying informed and making meaningful connections in the legislative process.
            </p>

            <p className="text-gray-700 mb-4">
                Make your legislative outreach more effective—leverage PLS’s <strong>Member Bios</strong> today!
            </p>

            <p className="text-gray-700">
                <strong>Note:</strong> Access to the <strong>Member Bios</strong> feature is available exclusively to <strong>PLSGovTrac</strong> subscribers, providing an invaluable resource for legislative preparation and insight.
            </p>
        </div>
    );
};

export default PublicationsContent;
