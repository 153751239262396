import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { create_seminars_registrations, get_seminars } from '../../../../../services/services';
import sendEmail from '../../../../../services/email';

const SeminarRegistration = () => {
  const [seminarTypes, setSeminarTypes] = useState([])
  const formik = useFormik({
    initialValues: {
      seminar: '',
      attendees: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
    },
    validationSchema: Yup.object({
      seminar: Yup.string().required('The field is required.'),
      attendees: Yup.string()
        .required('The field is required.'),
      contactName: Yup.string()
        .required('The field is required.')
        .max(400, 'The field is too long.'),
      contactEmail: Yup.string()
        .required('The field is required.')
        .email('The e-mail address entered is invalid.')
        .max(400, 'The field is too long.'),
      contactPhone: Yup.string()
        .required('The field is required.')
        .matches(
          /^\+?[1-9]\d{1,14}$/,
          'The telephone number is invalid.'
        )
        .max(400, 'The field is too long.'),
    }),
    onSubmit: (values, actions) => {
      const data = {
        seminar: values.seminar,
        attendees: values.attendees,
        contactName: values.contactName,
        contactEmail: values.contactEmail,
        contactPhone: values.contactPhone
      }
      create_seminars_registrations(data)
      console.log("seminar types",seminarTypes)
      const seminar = seminarTypes?.filter((seminarType)=>seminarType._id === values.seminar)?.[0]
      console.log("seminar",seminar)
      console.log("values",values)
      const emailData = {
        senderName: values.contactName,
        senderEmail: values.contactEmail,
        emailTo: "sales@mypls.com",
        orgName: "MyPLS",
        subject: "Seminar Registration",
        htmlContent: `
         <html>
           <body>
              <h2>Seminar Registration</h2>
                        <p>${seminar?.seminarType.title}</p>
                        <table>
                          <tr><td>Contact Name: </td><td>${values.contactName}</td></tr>
                          <tr><td>Contact Email: </td><td><a href='mailto:${values.contactEmail}'>${values.contactEmail}</a></td></tr>
                          <tr><td>Contact Phone: </td><td><a href='tel:1-${values.contactPhone}'>${values.contactPhone}</a></td></tr>
                          <tr><td>Attendees: </td><td>${values.attendees}</td></tr>
                          <tr><td>Seminar: </td><td>${seminar?.seminarType.title}</td></tr>
                          <tr><td>Date and Time: </td><td>${new Date(seminar?.scheduled).toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true,
                          })}</td></tr>
                        </table>


                        <hr>
                        <p>Tech Problem? <br>
                        <b><a href='mailto:teamTech@mypls.com?subject=Issue: Seminar Form'>Contact Team Tech</a></b></p>
            </body>
           </html>
                        `
      }
      sendEmail(emailData, "Confirmation Email is sent",()=>{})
      actions.resetForm()
    }
  });

  const fetch_types = async () => {
    const res = await get_seminars()
    setSeminarTypes(res)
  }

  useEffect(() => {
    fetch_types()
  }, [])

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-6">
      <div className="w-full max-w-2xl bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-3xl  mb-6 text-center font-medium font-playfair text-quoteText">Seminar Registration</h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2" htmlFor="seminar">
              Select Seminar (required)
            </label>
            <select
              id="seminar"
              name="seminar"
              className="w-full p-3 border focus:outline-none border-gray-400 rounded focus:border-purple1 transition-colors"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.seminar}
            >
              <option value="" label='Select seminar type' />
              {seminarTypes?.map((seminar) => (
                <option key={seminar?.seminarType.title} value={seminar._id} className='flex justify-between'>
                  <p>{seminar?.seminarType.title}--------------{new Date(seminar.scheduled).toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      })}</p>
                </option>
              ))}

            </select>
            {formik.touched.seminar && formik.errors.seminar ? (
              <div className="text-red-500 text-sm mt-1">{formik.errors.seminar}</div>
            ) : null}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2" htmlFor="attendees">
              Attendees Names (required) please enter name with comma separation.
            </label>
            <input
              type="text"
              id="attendees"
              name="attendees"
              className="w-full p-3 border focus:outline-none border-gray-400 rounded focus:border-purple1 transition-colors"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.attendees}
              required
            />
            {formik.touched.attendees && formik.errors.attendees ? (
              <div className="text-red-500 text-sm mt-1">{formik.errors.attendees}</div>
            ) : null}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2" htmlFor="contactName">
              Contact Name (required)
            </label>
            <input
              type="text"
              id="contactName"
              name="contactName"
              className="w-full p-3 border focus:outline-none border-gray-400 rounded focus:border-purple1 transition-colors"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.contactName}
              required
            />
            {formik.touched.contactName && formik.errors.contactName ? (
              <div className="text-red-500 text-sm mt-1">{formik.errors.contactName}</div>
            ) : null}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2" htmlFor="contactEmail">
              Contact Email (required)
            </label>
            <input
              type="email"
              id="contactEmail"
              name="contactEmail"
              className="w-full p-3 border focus:outline-none border-gray-400 rounded focus:border-purple1 transition-colors"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.contactEmail}
              required
            />
            {formik.touched.contactEmail && formik.errors.contactEmail ? (
              <div className="text-red-500 text-sm mt-1">{formik.errors.contactEmail}</div>
            ) : null}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2" htmlFor="contactPhone">
              Contact Phone (required)
            </label>
            <input
              type="tel"
              id="contactPhone"
              name="contactPhone"
              className="w-full p-3 border focus:outline-none border-gray-400 rounded focus:border-purple1 transition-colors"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.contactPhone}
              required
            />
            {formik.touched.contactPhone && formik.errors.contactPhone ? (
              <div className="text-red-500 text-sm mt-1">{formik.errors.contactPhone}</div>
            ) : null}
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="px-4 py-2 bg-purple1 text-white rounded hover:bg-purpleLight transition"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SeminarRegistration;
