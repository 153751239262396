import React, { createContext, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Create a Context
const ToastContext = createContext();

// Custom hook to use the ToastContext
export const useToast = () => {
  return useContext(ToastContext);
};

// Function to show toast (can be used outside of components)
export const showToast = (message, type = 'default', style = { backgroundColor: '#6c389c', color: 'white' }) => {
  switch (type) {
    case 'success':
      toast.success(message, { style });
      break;
    case 'error':
      toast.error(message, { style });
      break;
    case 'warn':
      toast.warn(message, { style });
      break;
    case 'info':
      toast.info(message, { style });
      break;
    default:
      toast(message, { style });
      break;
  }
};

// ToastProvider component
export const ToastProvider = ({ children }) => {
  return (
    <ToastContext.Provider value={showToast}>
      {children}
      <ToastContainer position="top-right" autoClose={2000} hideProgressBar={false} />
    </ToastContext.Provider>
  );
};
