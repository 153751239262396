import { Box, Modal } from '@mui/material';
import React, { useState } from 'react';
import { FaEnvelope } from 'react-icons/fa';
import ContactForm from '../ContactUs/ContactForm';

const ProfileCard = ({ name, imageSrc, title, paragraphs, email }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => {
        setIsOpen(!isOpen)
    }
    return (
        <div className="flex flex-col md:flex-row w-full mx-auto p-6 bg-white rounded-lg shadow-lg transition-transform duration-500 ease-in-out hover:scale-105 hover:shadow-custom">
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "40%",
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    pt: 2,
                    px: 4,
                    pb: 3,
                }}>
                    <ContactForm email={email} onClose={handleClose}/>
                </Box>
            </Modal>
            <div className="flex flex-col justify-center items-center md:w-[35%] lg:w-[25%] p-4">
                <div className="text-xl font-semibold font-playfair mb-2 text-center">{name}</div>
                <img className="w-40 h-40 rounded-full shadow-md mb-4 transform transition-transform duration-300 ease-in-out hover:scale-110" src={imageSrc} alt={name} />
                <div className="text-lg font-medium font-playfair mb-2 text-center">{title}</div>
                <div className="flex justify-center items-center w-10 h-10 rounded-full bg-purple2 shadow-md transition-transform duration-300 ease-in-out hover:scale-110" onClick={handleClose}>
                    <FaEnvelope className="text-white cursor-pointer" size={20} />
                </div>
            </div>
            <div className="flex flex-col md:w-[65%] lg:w-[75%] p-4">
                {paragraphs.map((para, index) => (
                    <p key={index} className={`text-base text-gray-700 mb-4 ${para.italic ? 'italic' : ''}`}>
                        {para.text}
                    </p>
                ))}
            </div>
        </div>
    );
};

export default ProfileCard;
