import React from 'react';
import DashboardLayout from '../../components/DashboardLayout/DashboardLayout';
import JobTable from '../../components/JobOpeningTable/JobOpeningTable';

const JobOpeningsPage = () => {
  return (
    <DashboardLayout>
      <JobTable />
    </DashboardLayout>
  );
};

export default JobOpeningsPage;