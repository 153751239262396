import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import sendEmail from '../../../../services/email';

const FreeTrialForm = ({onClose}) => {
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            organization: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            phone: Yup.string().required('Phone number is required'),
            organization: Yup.string().required('Organization is required'),
        }),
        onSubmit: (values) => {
            const emailData = {
                senderName: values.name,
                senderEmail: values.email,
                emailTo: "sales@mypls.com",
                orgName: "MyPLS",
                subject: "Free Trial Request",
                htmlContent: `
                 <html>
                   <body>
                      <h2>Free Trial Request</h2>
                                    <p>
                                    <table>
                                    <tr><td>Name: </td><td>${values.name}</td></tr>
                                    <tr><td>Email: </td><td><a href='mailto:${values.email}?subject=Your Free Trial of PLS!'>${values.email}</a></td></tr>
                                    <tr><td>Phone: </td><td><a href='tel:1-[your-phone]'>${values.phone}</a></td></tr>
                                    <tr><td>Company: </td><td>${values.organization}</td></tr>
                                    </table>
                                    </p>
                                <p><b><a href='https://clients.mypls.com/invite'>Create User Page</a></b></p>
                                </body>
                            </html>
                                `
            }
            sendEmail(emailData,"Free Trial Request Submitted",onClose)

        },
    });

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 w-full h-full shadow-lg">
            <div className="bg-white rounded-lg w-full transform transition-all duration-500 ease-in-out hover:scale-105">
                <div className="w-full bg-white p-8 shadow-lg rounded-md">
                    <h2 className="text-2xl font-semibold mb-4">PLS Free Trial Request</h2>
                    <p className="text-gray-700 mb-6">Tell us a little about yourself.</p>

                    <form onSubmit={formik.handleSubmit}>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium text-gray-700">
                                What is your name? <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                name="name"
                                className={`w-full p-2 border ${formik.errors.name && formik.touched.name ? 'border-red-500' : 'border-gray-300'} rounded-md focus:ring focus:ring-blue-300 outline-none`}
                                placeholder="Jenny Smith"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                            />
                            {formik.touched.name && formik.errors.name ? (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.name}</div>
                            ) : null}
                        </div>

                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium text-gray-700">
                                What is your email address? <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="email"
                                name="email"
                                className={`w-full p-2 border ${formik.errors.email && formik.touched.email ? 'border-red-500' : 'border-gray-300'} rounded-md focus:ring focus:ring-blue-300 outline-none`}
                                placeholder="jenny@example.com"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
                            ) : null}
                        </div>

                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium text-gray-700">
                                What is a phone number we can use to reach you? <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="tel"
                                name="phone"
                                className={`w-full p-2 border ${formik.errors.phone && formik.touched.phone ? 'border-red-500' : 'border-gray-300'} rounded-md focus:ring focus:ring-blue-300 outline-none`}
                                placeholder="717-867-5309"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone}
                            />
                            {formik.touched.phone && formik.errors.phone ? (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.phone}</div>
                            ) : null}
                        </div>

                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium text-gray-700">
                                What organization are you affiliated with? <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                name="organization"
                                className={`w-full p-2 border ${formik.errors.organization && formik.touched.organization ? 'border-red-500' : 'border-gray-300'} rounded-md focus:ring focus:ring-blue-300 outline-none`}
                                placeholder="Examples Inc."
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.organization}
                            />
                            {formik.touched.organization && formik.errors.organization ? (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.organization}</div>
                            ) : null}
                        </div>

                        <div className="flex justify-center mt-6">
                            <button type="submit" className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300">
                                Request Free Trial
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default FreeTrialForm;
