import axios from 'axios';
import { HOST } from '../configs/hosts'
import { TOKEN_KEY } from '../contants/contants';
const getAxiosInstance = async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    return axios.create({
        baseURL: `${HOST}`,
        headers: {
            'Content-Type': 'application/json',
            authorization: token ? `Bearer ${token}` : null,
        },
    });
}
export { getAxiosInstance };