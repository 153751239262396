import React from 'react';

const ServicesContentDisplay = ({ title, subtitle, description, items, footer }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-lg my-6">
      <h2 className="text-3xl font-bold mb-4 text-center">{title}</h2>
      <h3 className="text-xl font-semibold mb-4 text-center">{subtitle}</h3>
      <p className="text-gray-700 mb-6">{description}</p>

      {items && (
        <ul className="list-disc list-inside space-y-2 mb-6">
          {items.map((item, index) => (
            <li key={index} className="text-gray-700">
              <span className="font-semibold">{item.title}:</span> {item.text}
            </li>
          ))}
        </ul>
      )}

      {footer && <p className="text-center text-gray-600 mt-6">{footer}</p>}
    </div>
  );
};

export default ServicesContentDisplay;
