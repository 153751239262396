import React from 'react'
import DashboardLayout from '../../components/DashboardLayout/DashboardLayout'
import SeminarTypeTable from '../../components/SeminarTypes/SeminarTypeTable'

const SeminarTypes = () => {
    return (
        <DashboardLayout>
            <SeminarTypeTable />
        </DashboardLayout>
    )
}

export default SeminarTypes