import React from 'react';
import { Link } from 'react-router-dom';

const ReferenceMaterials = () => {
  return (
    <div className="flex justify-center items-center min-h-[80.5vh] bg-gray-100 p-6">
      <div className="w-full max-w-4xl bg-white p-8 rounded-lg shadow-custom">
        <h2 className="text-3xl mb-4 text-center font-medium font-playfair text-quoteText">Reference Materials</h2>
        <p className="text-center mb-6">
          Below you will find detailed videos on how to best utilize the services of PLS. Simply click on an option from the list of items below to access tutorials on how to use the system.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h3 className="text-2xl font-medium mb-2">General Information</h3>
            <ul>
              <li className="mb-2">
                <a href="https://youtu.be/BXLh-Ysm4V4" target='_blank' className="text-blue-500 hover:text-blue-700 transition-colors duration-300">Communication Connection: Simplifying Government Relations</a>
              </li>
              <li className="mb-2">
                <a href="https://youtu.be/5RUoGrtTd6g" target='_blank' className="text-blue-500 hover:text-blue-700 transition-colors duration-300">Member Match Service Demo</a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-2xl font-medium mb-2">PLS GovTrac</h3>
            <ul>
              <li className="mb-2">
                <a href="https://youtu.be/2VWOwKjAzYM" target='_blank' className="text-blue-500 hover:text-blue-700 transition-colors duration-300">Dashboard Overview</a>
              </li>
              <li className="mb-2">
                <a href="https://youtu.be/nV3X0m0vFwg" target='_blank' className="text-blue-500 hover:text-blue-700 transition-colors duration-300">Daily Alert and Daily Journal Overview</a>
              </li>
              <li className="mb-2">
                <a href="https://youtu.be/heeYzWk4ONY" target='_blank' className="text-blue-500 hover:text-blue-700 transition-colors duration-300">PLSGovTrac System Overview</a>
              </li>
              <li className="mb-2">
                <a href="https://youtu.be/pDvHrweasHM" target='_blank' className="text-blue-500 hover:text-blue-700 transition-colors duration-300">Report Generation</a>
              </li>
              <li className="mb-2">
                <a href="https://youtu.be/ARhkduCEzuE" target='_blank' className="text-blue-500 hover:text-blue-700 transition-colors duration-300">Creating a Track and Tracking Bills</a>
              </li>
              <li className="mb-2 text-blue-500 hover:text-blue-700 transition-colors duration-300">
                <Link to={"/classroom/reference-materials/PLSGovtracCollaborationGuide"} className="text-blue-500 hover:text-blue-700 transition-colors duration-300">PLSGovTrac Track Collaboration System Guide</Link>
              </li>
              <li className="mb-2">
                <a href="https://youtu.be/s3-QdSIGANM" target='_blank' className="text-blue-500 hover:text-blue-700 transition-colors duration-300">PLSGovTrac Track Collaboration System Tutorial</a>
              </li>
            </ul>
          </div>
        </div>
        <p className="text-center mt-6">
          If you are in need of further help, please contact us at 717-236-6984 or email us at <a href="mailto:mypls@mypls.com" className="text-blue-500 hover:text-blue-700 transition-colors duration-300">mypls@mypls.com</a>.
        </p>
      </div>
    </div>
  );
};

export default ReferenceMaterials;
