import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { get_seminars_types } from '../../../../services/services';

const SeminarFormModal = ({ closeModal, onSubmit, initialValues }) => {
  const [seminarTypes,setSeminarTypes] = useState([])
  const SeminarSchema = Yup.object().shape({
    type: Yup.string().required('Seminar type is required'),
    date: Yup.date().required('Seminar date and time are required').nullable(),
  });

  const defaultValues = {
    type: initialValues?.seminarType._id || "",
    date: initialValues?.scheduled ? new Date(initialValues.scheduled) : null,
  };

useEffect(()=>{
  const types = async () => {
    const seminar_types = await get_seminars_types();
    setSeminarTypes(seminar_types)
  };
  types();
  console.log("seminars lll", initialValues);
},[])
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-bold mb-4">{initialValues ? 'Update Seminar' : 'Create Seminar'}</h2>

        <Formik
          initialValues={defaultValues}
          validationSchema={SeminarSchema}
          onSubmit={(values) => {
            if(initialValues){
              onSubmit(values,initialValues._id)
            }
            onSubmit(values);
            closeModal();
          }}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="mb-4">
                <label className="block mb-1 text-sm font-medium">Seminar Type</label>
                <Field
                  as="select"
                  name="type"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-300 outline-none"
                >
                  <option value="" label='Select seminar type'/>
                  {seminarTypes?.map((type) => (
                    <option key={type.title} value={type._id}>
                      {type.title}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="type" component="div" className="text-red-500 text-sm mt-1" />
              </div>

              <div className="mb-4">
                <label className="block mb-1 text-sm font-medium">Seminar Date & Time</label>
                <DatePicker
                  selected={values.date}
                  onChange={(date) => setFieldValue('date', date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="yyyy/MM/dd h:mm aa"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-300 outline-none"
                />
                <ErrorMessage name="date" component="div" className="text-red-500 text-sm mt-1" />
              </div>

              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-400 text-white rounded-md hover:bg-gray-500 transition"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
                >
                  {initialValues ? 'Update' : 'Create'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SeminarFormModal;
