import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastProvider } from './context/toastContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ToastProvider>
         <App />
    </ToastProvider>
);

reportWebVitals();
