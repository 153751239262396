import React from 'react';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';

const PLSGovTracGuide = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Header Section */}
      <Header />

      {/* Main Content */}
      <div className="flex-grow">
        <div className="max-w-4xl mx-auto p-6">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">
            Introducing PLSGovtrac's Track Collaboration System
          </h1>
          <p className="text-lg text-gray-700 mb-6">
            PLSGovtrac now features a Track Collaboration System that lets you work with external contacts to streamline the sharing of analyses and comments on specific bills that you are tracking.
          </p>
          
          <h2 className="text-xl font-bold text-gray-900 mb-2">Getting Started</h2>

          <h3 className="text-lg font-semibold text-gray-900">1. Create Contacts:</h3>
          <ul className="list-disc list-inside text-gray-700 mb-6">
            <li>Under your profile menu, navigate to the <strong>My Contacts</strong> section.</li>
            <li>
              Click the <strong>Add Contact</strong> button to enter an external contact's details (name, title, company, department, email, and phone number).
            </li>
            <li>
              Create a password for them. <strong>Important:</strong> Check the box so they receive their daily reports, enabling them to upload analyses and comments.
            </li>
            <li>After creating a contact, it will appear in your <strong>My Contacts</strong> list.</li>
          </ul>

          <h3 className="text-lg font-semibold text-gray-900">2. Assign Tracks:</h3>
          <ul className="list-disc list-inside text-gray-700 mb-6">
            <li>
              To assign a track to a collaborator, go to the <strong>Tracks</strong> menu, select the desired track, and click the edit icon to make the track private.
            </li>
            <li>
              You can then choose which contact to assign from the External Contact list.
            </li>
          </ul>

          <h2 className="text-xl font-bold text-gray-900 mb-2">Daily Reports</h2>
          <ul className="list-disc list-inside text-gray-700 mb-6">
            <li>
              Once assigned, your contact will receive a daily email report, <strong>Daily Report on Assigned Bills</strong>, that includes:
              <ul className="list-disc list-inside ml-6">
                <li>Their contact information and password.</li>
                <li>Updates on assigned bills, including recent activity and newly assigned legislation.</li>
                <li>An index of all assigned bills, indicating whether analyses or comments have been uploaded.</li>
              </ul>
            </li>
          </ul>

          <h2 className="text-xl font-bold text-gray-900 mb-2">Uploading and Editing</h2>
          <ul className="list-disc list-inside text-gray-700 mb-6">
            <li>
              Your contact can upload new comments or files by clicking the bill number in their report and entering them on the abbreviated version of the bill page.
            </li>
            <li>
              They can also edit previously entered comments, and you will be notified of any edits made to existing comments or files.
            </li>
            <li>
              Comments or files uploaded by your contact will be reflected in your daily report, <strong>Daily Report on External Users</strong>, and saved in the <strong>My Comments</strong> and <strong>My Files</strong> sections of the bill in your PLSGovTrac account.
            </li>
          </ul>

          <h2 className="text-xl font-bold text-gray-900 mb-2">Monitoring External Users</h2>
          <ul className="list-disc list-inside text-gray-700 mb-6">
            <li>
              As the creator of the collaboration, you’ll receive a report, <strong>Daily Report on External Users</strong>, showing:
              <ul className="list-disc list-inside ml-6">
                <li>New comments and files added by external users.</li>
                <li>Bills that are missing analyses or comments after passing the first chamber.</li>
              </ul>
            </li>
          </ul>

          <h2 className="text-xl font-bold text-gray-900 mb-2">Maintaining Continuity</h2>
          <ul className="list-disc list-inside text-gray-700 mb-6">
            <li>
              If an assigned contact leaves, you can easily update their name while keeping the company and password the same. This ensures continuity in accessing previously uploaded analyses.
            </li>
          </ul>

          <p className="text-sm text-gray-700 mb-6">
            <strong>NOTE:</strong> Legislative Liaisons asking to have bills assigned to their tracking must contact Talia or Jaclyn at OGC to have those bills placed in their assigned tracks.
          </p>

          <p className="text-sm text-gray-700 mb-6">
            For any questions about the Track Collaboration Tool, please contact us at <a href="mailto:info@mypls.com" className="text-blue-500 underline">info@mypls.com</a> or call (717) 236-6984. Thank you!
          </p>
        </div>
      </div>

      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default PLSGovTracGuide;
