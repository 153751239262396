import React from 'react'
import SeminarTable from '../../components/SeminarTable/SeminarTable'
import DashboardLayout from '../../components/DashboardLayout/DashboardLayout'

const Seminars = () => {
    return (
        <DashboardLayout>
            <SeminarTable />
        </DashboardLayout>
    )
}

export default Seminars