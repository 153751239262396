import axios from 'axios';
import { showToast } from '../context/toastContext';

const sendEmail = async (emailData,message,onClose) => {
  const API_KEY = process.env.REACT_APP_SIB_API_KEY;  // Replace with your API Key
  const API_URL = 'https://api.sendinblue.com/v3/smtp/email';
  
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'api-key': API_KEY,
    },
  };

  const data = {
    sender: { name:emailData.senderName, email:emailData.senderEmail }, // Sender's email
    to: [
      { email: emailData.emailTo || 'mypls@mypls.com', name:emailData.orgName || 'MyPLS' }, // Recipient
    ],
    subject: emailData.subject, // Subject from the form
    htmlContent: emailData.htmlContent || `<p>${emailData.message}</p>`, // HTML message content
  };

  try {
    const response = await axios.post(API_URL, data, config);
    showToast(message,"success")
    onClose()
    return response.data;
  } catch (error) {
    console.error('Error sending email:', error.response);
    showToast("Error sending email","error")
  }
};

export default sendEmail;