import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import AboutUsCard from '../../components/AboutUsCard/AboutUsCard'
import { teampls } from '../../constants/aboutus'

const PLSTeam = () => {
    return (
        <div className='flex w-screen h-screen flex-col gap-4 overflow-y-scroll'>
            <Header />
            <div className='flex flex-col w-full justify-center items-center'>
                <div className='flex flex-col gap-10 w-[80%] justify-center items-center'>
                    {
                        teampls.map((data,i)=>(
                            <AboutUsCard imageSrc={data.image} name={data.name} title={data.title} paragraphs={data.paragraphs} email={data.email} key={i}/>
                        ))
                    }
                   
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PLSTeam