import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import { Box, Button, Modal } from '@mui/material'
import VideoPlayer from './VideoPlayer'
import { FaBalanceScale, FaMedkit, FaUniversity } from 'react-icons/fa'
import { FaBriefcase, FaGavel, FaGraduationCap, FaHandshake, FaNewspaper, FaUser, FaUsers } from 'react-icons/fa6'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Footer from '../../components/Footer/Footer'
import "./carousel.css"
import { useSearchParams } from 'react-router-dom'
import { redirectUrlGovTrac, redirectUrlPaGov2Go, redirectUrlPlsPublications, redirectUrlPressPass } from '../../constants/constants'
import FreeTrialForm from '../../components/FreeTrialForm/FreeTrialForm'
const Home = () => {
  const [searchParams] = useSearchParams();
  const authToken = searchParams.get('auth');
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    const authToken = searchParams.get('auth');

    const authToken2 = localStorage.getItem("authToken")
    if (authToken) {
      try {
        const decodedToken = JSON.parse(atob(authToken));
        // Save auth token to localStorage
        localStorage.setItem('authToken', authToken);
        // Remove 'auth' from the query parameters
        const url = new URL(window.location.pathname)
        const hasAuth = url.searchParams.has("auth")
        if (!hasAuth) {
          return
        }
        if (hasAuth) {
          console.log("has auth")
          url.searchParams.delete("auth")
        }
      } catch (error) {
        console.error('Invalid token:', error.message);
      }
    } else if (authToken2) {
      const decodedToken = JSON.parse(atob(authToken2));
      // Save auth token to localStorage
      searchParams.delete('auth');
    } else {
      console.log('Auth token is missing or not a valid string');
    }
  }, [searchParams]);
  return (
    <div className='flex w-screen h-screen flex-col gap-4 overflow-y-scroll'>
      <Header />
      {/* container */}
      <div className='flex flex-col w-full justify-center items-center'>
        <img className='cursor-pointer' width={308} height={200} src='/img/logoimage.png' alt="MYPLSLogo" />
        <Modal
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "40%",
            bgcolor: 'background.paper',
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
          }}>
            <FreeTrialForm onClose={handleClose} />
          </Box>
        </Modal>
        <div className='flex flex-col gap-10 w-[70%] justify-center items-center'>
          <div className='flex w-full justify-center items-center flex-col gap-2'>
            <h2 className='text-center text-xl font-medium pt-10'>
              Pennsylvania's Trusted Leader in Legislative Research and Intelligence Solutions
            </h2>
            <p className='text-center w-[80%]'>
              PLS is Pennsylvania's most powerful legislative research and tracking tool, designed specifically for government relations professionals. With on-site capabilities and a "boots on the ground" presence at the Capitol, we deliver real-time insights and critical updates as policy developments unfold. Our platform, built by experts with over 150 years of combined state government experience, empowers you to stay ahead with customizable, in-depth legislative tracking and analysis. Accessible from anywhere, PLS enhances your productivity and gives you the strategic edge needed to influence outcomes.
            </p>
            <p className='text-center w-[70%]'>
              Sign up for a free trial today and experience the competitive advantage that only PLS can provide.
            </p>
            <Button sx={{
              borderColor: '#9B51E0',
              color: '#9B51E0',
              textTransform: "capitalize",
              marginTop: "15px",
              '&:hover': {
                color: 'white',
                backgroundColor: '#9B51E0',
                outline: "#9B51E0"
              },
            }} variant='outlined' onClick={handleClose} className='gap-2'>
              Free Trial
            </Button>
          </div>
          <div className="flex w-full flex-col justify-center gap-4">
            <div className="flex w-full justify-center p-4 text-xl md:text-2xl lg:text-3xl font-medium font-playfair text-quoteText">
              Featured Services
            </div>
            <div className="flex flex-wrap w-full gap-4 justify-center sm:flex-nowrap md:flex-wrap lg:flex-nowrap">
              <a
                href={`https://clients.mypls.com/login?redirect=${redirectUrlPlsPublications}`}
                className="flex flex-col w-[300px] h-[auto] rounded-lg border-[1px] border-purple1 cursor-pointer hover:shadow-custom3 transition-transform duration-300 ease-in-out hover:scale-105"
              >
                <img src="/img/V2-Capitol-Recap-myplscom.png" className="rounded-t-lg" />
                <div className="flex w-full items-center justify-center text-center p-2 font-normal text-[1rem] sm:text-[1.25rem] text-purple1">
                  PLS Publications
                </div>
              </a>
              <a
                href={`https://clients.mypls.com/login?redirect=${redirectUrlGovTrac}`}
                className="flex flex-col w-[300px] h-[auto] rounded-lg border-[1px] border-purple1 cursor-pointer hover:shadow-custom3 transition-transform duration-300 ease-in-out hover:scale-105"
              >
                <img src="/img/PLSGovTrac myplscom.jpg" className="rounded-t-lg" />
                <div className="flex w-full items-center justify-center text-center p-2 font-normal text-lg sm:text-xl text-purple1">
                  PLSGovTrac
                </div>
              </a>
              <a
                href={`https://clients.mypls.com/login?redirect=${redirectUrlPressPass}`}
                className="flex flex-col w-[300px] h-[auto] rounded-lg border-[1px] border-purple1 cursor-pointer hover:shadow-custom3 transition-transform duration-300 ease-in-out hover:scale-105"
              >
                <img src="/img/Press-Pass-new-image.png" className="rounded-t-lg" />
                <div className="flex w-full items-center justify-center text-center p-2 font-normal text-lg sm:text-xl text-purple1">
                  Communication Connection
                </div>
              </a>
              <a
                href={`https://clients.mypls.com/login?redirect=${redirectUrlPaGov2Go}`}
                className="flex flex-col w-[300px] h-[auto] rounded-lg border-[1px] border-purple1 cursor-pointer hover:shadow-custom3 transition-transform duration-300 ease-in-out hover:scale-105"
              >
                <img src="/img/PAGov2GoMyplscom.png" className="rounded-t-lg" />
                <div className="flex w-full items-center justify-center text-center p-2 font-normal text-lg sm:text-xl text-purple1">
                  PAGov2Go
                </div>
              </a>
            </div>
          </div>

          <div className='flex w-full flex-wrap'>
            <div className='flex flex-col w-full lg:w-[50%] md:w-full p-4'>
              <div className='text-lg font-semibold'>
                Why PLS
              </div>
              <div className='text-base'>
                Whether you are a one person lobbying firm, a state agency, or a Fortune 500 company—if you do business in Pennsylvania, we can help you do your job, and do it better. Our staff of experts work extensively with each client to provide unmatched training and superior customer support throughout the life of your subscription. Contact us today to learn how PLS can put you in the driver's seat of Pennsylvania state government.
              </div>
              <div className='flex w-full justify-center'>
                <Button sx={{
                  borderColor: '#9B51E0',
                  color: '#9B51E0',
                  marginTop: "15px",
                  textTransform: "capitalize",
                  '&:hover': {
                    color: 'white',
                    backgroundColor: '#9B51E0',
                    outline: "#9B51E0"
                  },
                }} variant='outlined' onClick={handleClose} className='gap-2'>
                  Free Trial
                </Button>
              </div>
            </div>
            <div className='w-full lg:w-[50%] md:w-full' >
              <VideoPlayer />
            </div>
          </div>
          <div className='flex w-full flex-wrap'>
            <div className='flex w-full justify-center p-4 text-xl font-medium font-playfair text-quoteText'>Who We Serve</div>
            <div className='flex items-center gap-3 w-full lg:w-[50%] md:w-full p-4'>
              <div className='flex justify-center items-center p-3 border-homeIconBrown border-[2px] rounded'>
                <FaMedkit size={32} className='text-homeIconBrown animate-bounce' />
              </div>

              <div className='flex flex-col'>
                <h1 className='text-xl font-normal'>
                  Health & Medical Facilities
                </h1>
                <div className='text-sm'>
                  Receive updates on new legislative and regulatory issues affecting health care, as well as possible changes in policy or procedure.
                </div>
              </div>
            </div>

            <div className='flex items-center gap-3 lg:w-[50%] md:w-full p-4'>
              <div className='flex justify-center items-center p-3 border-homeIconBrown border-[2px] rounded'>
                <FaNewspaper size={32} className='text-homeIconBrown animate-bounce' />
              </div>
              <div className='flex flex-col'>
                <h1 className='text-xl font-normal'>
                  Press
                </h1>
                <div className='text-sm'>
                  Research everything you need for your next breaking story: votes, committees, bills, legislators, campaigns and more.
                </div>
              </div>
            </div>

            <div className='flex items-center gap-3 lg:w-[50%] md:w-full p-4'>
              <div className='flex justify-center items-center p-3 border-homeIconBrown border-[2px] rounded'>
                <FaUniversity size={32} className='text-homeIconBrown animate-bounce' />
              </div>
              <div className='flex flex-col'>
                <h1 className='text-xl font-normal'>
                  State Government
                </h1>
                <div className='text-sm'>
                  Create a custom bill tracking system and quickly view comprehensive lists of legislation with matching statutes.
                </div>
              </div>
            </div>

            <div className='flex items-center gap-3 lg:w-[50%] md:w-full p-4'>
              <div className='flex justify-center items-center p-3 border-homeIconBrown border-[2px] rounded'>
                <FaHandshake size={32} className='text-homeIconBrown animate-bounce' />
              </div>
              <div className='flex flex-col'>
                <h1 className='text-xl font-normal'>
                  Public Relations
                </h1>
                <div className='text-sm'>
                  Monitor Pennsylvania state government news and information.
                </div>
              </div>
            </div>

            <div className='flex items-center gap-3 lg:w-[50%] md:w-full p-4'>
              <div className='flex justify-center items-center p-3 border-homeIconBrown border-[2px] rounded'>
                <FaGavel size={32} className='text-homeIconBrown animate-bounce' />
              </div>
              <div className='flex flex-col'>
                <h1 className='text-xl font-normal'>
                  Local Government
                </h1>
                <div className='text-sm'>
                  Research all legislative information from your county, township, borough, town, city and school district.
                </div>
              </div>
            </div>

            <div className='flex items-center gap-3 lg:w-[50%] md:w-full p-4'>
              <div className='flex justify-center items-center p-3 border-homeIconBrown border-[2px] rounded'>
                <FaBalanceScale size={32} className='text-homeIconBrown animate-bounce' />
              </div>
              <div className='flex flex-col'>
                <h1 className='text-xl font-normal'>
                  Law Firms
                </h1>
                <div className='text-sm'>
                  Assign proposed bills and amendments to experts for analysis.
                </div>
              </div>
            </div>

            <div className='flex items-center gap-3 lg:w-[50%] md:w-full p-4'>
              <div className='flex justify-center items-center p-3 border-homeIconBrown border-[2px] rounded'>
                <FaGraduationCap size={32} className='text-homeIconBrown animate-bounce' />
              </div>
              <div className='flex flex-col'>
                <h1 className='text-xl font-normal'>
                  Educational Institutions
                </h1>
                <div className='text-sm'>
                  Conduct research and track legislation that could impact your educational institution.
                </div>
              </div>
            </div>

            <div className='flex items-center gap-3 lg:w-[50%] md:w-full p-4'>
              <div className='flex justify-center items-center p-3 border-homeIconBrown border-[2px] rounded'>
                <FaBriefcase size={32} className='text-homeIconBrown animate-bounce' />
              </div>
              <div className='flex flex-col'>
                <h1 className='text-xl font-normal'>
                  Corporations
                </h1>
                <div className='text-sm'>
                  Actively manage government relations and easily keep stakeholders updated.
                </div>
              </div>
            </div>

            <div className='flex items-center gap-3 lg:w-[50%] md:w-full p-4'>
              <div className='flex justify-center items-center p-3 border-homeIconBrown border-[2px] rounded'>
                <FaUsers size={32} className='text-homeIconBrown animate-bounce' />
              </div>
              <div className='flex flex-col'>
                <h1 className='text-xl font-normal'>
                  Associations
                </h1>
                <div className='text-sm'>
                  Communicate your legislative agenda to members and stakeholders with PLS' custom website enhancements.
                </div>
              </div>
            </div>

            <div className='flex items-center gap-3 lg:w-[50%] md:w-full p-4'>
              <div className='flex justify-center items-center p-3 border-homeIconBrown border-[2px] rounded'>
                <FaUser size={32} className='text-homeIconBrown animate-bounce' />
              </div>
              <div className='flex flex-col'>
                <h1 className='text-xl font-normal'>
                  Lobbyists
                </h1>
                <div className='text-sm'>
                  Develop custom reports for each of your clients as you manage their legislative issues.
                </div>
              </div>
            </div>
          </div>
          <div className='flex w-full flex-wrap'>
            <div className='flex w-full justify-center p-4 text-xl font-medium font-playfair text-quoteText'>What our clients say about us</div>
            <div className='w-full lg:w-3/4 xl:w-2/3 mx-auto'><Carousel showArrows={true}
              autoPlay={true}
              infiniteLoop={true}
              showThumbs={false}
              showStatus={false}
              interval={2000}>
              <div className='flex ml-6'>
                <blockquote className='italic border-l-[4px] border-[#ddd] text-sm m-7 font-merriweather text-quoteText'>
                  "The tools and resources that PLS provides like the Capitol
                  Toolbox and Press Pass [now known as <em>Communication Connection</em>] are invaluable to me as the Director
                  of Legislative Affairs for a Professional Association. The
                  in-depth reporting from local reporters who understand the
                  intricacies of PA Politics helps provide great insight for
                  me and my members."
                  <h3 className='py-4 font-playfair'>Amal Mahrouki</h3>
                  <p>
                    Director of Legislative Affairs<br />AIA Pennsylvania
                  </p>
                </blockquote>
              </div>
              <div className='p-4'>
                <blockquote className='italic border-l-[4px] border-[#ddd] text-sm m-7 font-merriweather text-quoteText'>
                  "I love having PLS' service available to research and track
                  information that is necessary to effectively serve my
                  clients. I could not be as successful without PLS service
                  and team of professionals who are always available to help.
                  PLS' website is a great tool and easy to use for in depth
                  research and tracking as well as for a quick inquiry. Often
                  in lobbying, things change quickly and I love having the
                  ability to rely on PLS from my iphone to confirm new and/or
                  updated information."
                  <h3 className='py-4 font-playfair'>Kathleen Duffy Bruder</h3>
                  <p>Saxton &amp; Stump</p>
                </blockquote>
              </div>
              <div className=' p-4'>
                <blockquote className='italic border-l-[4px] border-[#ddd] text-sm m-7 font-merriweather text-quoteText'>
                  "...[T]he new website provided the information I needed, and the setup was perfect...
                  Now that I know where to go to access information for my daily and weekly reports,
                  [it] gives me confidence that I am providing the best intel in a format my members like and understand.
                  The way your new website works, and the information provided, makes your site the best one in the country.
                  I have worked on all websites in the country that focus on government, bills, committees, and general state legislation.
                  After I spent a week on your new website the changes you made are impressive."
                  <h3 className='py-4 font-playfair'>Jeff Levy</h3>
                  <p>Pennsylvania Hospitality and Entertainment Association</p>
                </blockquote>
              </div>
              <div className=' p-4'>
                <blockquote className='flex flex-col italic border-l-[4px] border-[#ddd] text-sm m-7 h-auto font-merriweather text-quoteText'>
                  "PLS' legislative research tools and their personalized,
                  detailed trainings, have helped our team work efficiently
                  and effectively for our clients."
                  <h3 className='py-4 font-playfair'>Ridge Policy Group</h3>
                </blockquote>
              </div>
            </Carousel>

            </div>

          </div>
          <div className='flex flex-col w-full justify-center items-center'>
            <h2 className='font-medium text-[22px] font-playfair text-quoteText'>
              Want to know more?
            </h2>
            <Button sx={{
              borderColor: '#9B51E0',
              color: '#9B51E0',
              marginTop: "15px",
              textTransform: "capitalize",
              '&:hover': {
                color: 'white',
                backgroundColor: '#9B51E0',
                outline: "#9B51E0"
              },
            }} variant='outlined' onClick={handleClose} className='gap-2'>
              Free Trial
            </Button>
          </div>
        </div>
      </div>


      {/* container end */}
      <Footer />
    </div>
  )
}

export default Home