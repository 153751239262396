import React, { useEffect, useState } from 'react';
import { get_seminars } from '../../../../../services/services';
import { NavLink } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';

const UpCommingSeminars = () => {
  const [seminars, setSeminars] = useState([]);
  const [selectedSeminar, setSelectedSeminar] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true); // State for loading

  const fetchSeminars = async () => {
    setLoading(true);
    const data = await get_seminars();
    setSeminars(data);
    setLoading(false); // Data fetched, stop loading
  };

  useEffect(() => {
    fetchSeminars();
  }, []);

  const openModal = (seminar) => {
    setSelectedSeminar(seminar);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSeminar(null);
  };

  return (
    <div className="flex flex-col items-center py-10 min-h-screen">
      <h2 className="text-3xl mb-6 font-medium font-playfair text-quoteText">Upcoming Seminars</h2>
      <NavLink to="/classroom/seminar-registration" className="mb-4 hover:underline font-semibold">
        Click Here to Register
      </NavLink>

      {/* Show loading spinner if data is still being fetched */}
      {loading ? (
        <div className="flex justify-center items-center min-h-[50vh]">
          <CircularProgress />
        </div>
      ) : (
        <div className="w-full max-w-4xl px-4">
          {seminars?.map((seminar, index) => (
            <div
              key={index}
              onClick={() => openModal(seminar)}
              className="flex flex-col cursor-pointer sm:flex-row justify-between items-center bg-white shadow-md rounded-lg mb-4 p-4 transition-transform duration-300 ease-in-out hover:scale-105"
            >
              <div className="text-lg font-medium text-gray-800">{new Date(seminar?.scheduled).toLocaleString('en-US', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              })}</div>
              <div className="text-lg font-medium text-blue-600">{seminar?.seminarType?.title || seminar?.title}</div>
            </div>
          ))}
        </div>
      )}

      {isModalOpen && selectedSeminar && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={closeModal}>
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
            <h2 className="text-xl font-bold mb-4">{selectedSeminar?.seminarType.title}</h2>
            <p className="text-gray-700 mb-6">{selectedSeminar?.seminarType.description}</p>
            <Button
              sx={{
                borderColor: '#9B51E0',
                color: '#9B51E0',
                marginTop: '15px',
                textTransform: 'capitalize',
                '&:hover': {
                  color: 'white',
                  backgroundColor: '#9B51E0',
                  outline: '#9B51E0',
                },
              }}
              variant="outlined"
              onClick={closeModal}
              className="gap-2"
            >
              Back
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpCommingSeminars;
