import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import sendEmail from '../../../../services/email';

// Validation schema using Yup
const validationSchema = Yup.object({
  name: Yup.string()
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  subject: Yup.string()
    .required('Subject is required'),
  message: Yup.string(), // Optional
});

const ContactForm = ({email, onClose}) => {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      subject: '',
      message: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values,actions) => {
      const emailData = {
        senderName:values.name,
        senderEmail:values.email,
        emailTo:email || "mypls@mypls.com",
        orgName:"MyPLS",
        subject:values.subject,
        message:values.message
      }
       sendEmail(emailData,"Thank you! A PLS representative will contact you shortly",onClose)
       actions.resetForm()
      // Handle form submission here
    },
  });

  return (
    <div className="fixed inset-0 flex items-center w-full justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full transform transition-all duration-500 ease-in-out hover:scale-105">
        <div className='flex w-full md:pb-5'>
          <img width={200} height={200} src='/img/logoimage.png' alt="Logo" />
        </div>
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        <p className="text-gray-700 mb-2">Pennsylvania Legislative Services</p>
        <p className="text-gray-700 mb-2">240 North Third Street, 8th Floor<br />Harrisburg, PA 17101</p>
        <p className="text-gray-700 mb-6">717-236-6984</p>

        <form onSubmit={formik.handleSubmit}>
          {/* Name Field */}
          <label className="block mb-2 text-sm font-medium text-gray-700">Your name</label>
          <input
            type="text"
            name="name"
            className={`block w-full p-2 mb-1 border ${formik.touched.name && formik.errors.name ? 'border-red-500' : 'border-gray-400'} outline-none rounded focus:border-purple1`}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.name && formik.errors.name ? (
            <p className="text-red-500 text-sm mb-4">{formik.errors.name}</p>
          ) : null}

          {/* Email Field */}
          <label className="block mb-2 text-sm font-medium text-gray-700">Your email</label>
          <input
            type="email"
            name="email"
            className={`block w-full p-2 mb-1 border ${formik.touched.email && formik.errors.email ? 'border-red-500' : 'border-gray-400'} outline-none rounded focus:border-purple1`}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email ? (
            <p className="text-red-500 text-sm mb-4">{formik.errors.email}</p>
          ) : null}

          {/* Subject Field */}
          <label className="block mb-2 text-sm font-medium text-gray-700">Subject</label>
          <input
            type="text"
            name="subject"
            className={`block w-full p-2 mb-1 border ${formik.touched.subject && formik.errors.subject ? 'border-red-500' : 'border-gray-400'} outline-none rounded focus:border-purple1`}
            value={formik.values.subject}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.subject && formik.errors.subject ? (
            <p className="text-red-500 text-sm mb-4">{formik.errors.subject}</p>
          ) : null}

          {/* Message Field (Optional) */}
          <label className="block mb-2 text-sm font-medium text-gray-700">Your message (optional)</label>
          <textarea
            name="message"
            className="block w-full p-2 mb-4 border border-gray-400 outline-none rounded h-32 focus:border-purple1"
            value={formik.values.message}
            onChange={formik.handleChange}
          />
          
          <div className="flex justify-end">
            <button type="submit" className="px-4 py-2 bg-purple1 text-white rounded hover:bg-purpleLight transition">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
