import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Button } from '@mui/material';
import { get_job_openings } from '../../../../services/services';


const Careers = () => {
    const [jobs, setJobs] = useState([]);
    const handleApplyClick = (email, jobTitle) => {
        const subject = `Application for ${jobTitle}`;
        const body = `Dear Hiring Manager,\n\nI would like to apply for the position of ${jobTitle}. Please find my resume and cover letter attached.\n\nThank you for your consideration.\n\nBest regards,`;
        window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    };

    const fetchJobOpenings = async () => {
        const data = await get_job_openings();
        setJobs(data)
      };
    
      useEffect(() => {
        fetchJobOpenings();
      }, []);

    return (
        <div className="bg-gray-100 min-h-screen flex flex-col justify-between">
            <Header />

            <main className="flex-grow">
                <div className="p-6">
                    {/* Company Overview / Introductory Message */}
                    <div className="max-w-4xl mx-auto bg-white p-8 rounded-md shadow-md mb-8">
                        <h1 className="text-3xl font-bold mb-4">Join Our Team and Shape Pennsylvania's Legislative Future</h1>
                        <p className="text-gray-700 mb-4">
                            At Pennsylvania Legislative Services, we are dedicated to providing top-tier legislative research, intelligence,
                            and tracking solutions that empower decision-makers and drive positive change in Pennsylvania. As a leader in our field,
                            we pride ourselves on fostering an environment where innovation, collaboration, and professional growth thrive.
                        </p>
                        <p className="text-gray-700 mb-4">
                            We are looking for passionate, driven individuals to join our dynamic team. Whether you're a seasoned expert or just starting your career,
                            you’ll have the opportunity to make an impact and grow alongside industry leaders. If you're ready to contribute to our mission and help
                            shape the legislative landscape, we’d love to hear from you.
                        </p>
                        <p className="text-gray-700">
                            Explore our current openings and take the next step in your career with us.
                        </p>
                    </div>

                    {/* Job Openings */}
                    <div className="max-w-4xl mx-auto bg-white p-8 rounded-md shadow-md">
                        <h2 className="text-2xl font-bold mb-6">Available Job Openings</h2>

                        {jobs?.map((job, index) => (
                            <div key={index} className="mb-6">
                                <h3 className="text-xl text-gray-700"><strong>Job Title:</strong> {job.jobTitle}</h3>
                                <p className="text-gray-700"><strong>Location:</strong> {job.location}</p>
                                <p className="text-gray-700 mb-4"><strong>Description:</strong> {job.description}</p>
                                <Button sx={{
                                    borderColor: '#9B51E0',
                                    color: '#9B51E0',
                                    marginTop: "15px",
                                    textTransform: "capitalize",
                                    '&:hover': {
                                        color: 'white',
                                        backgroundColor: '#9B51E0',
                                        outline: "#9B51E0"
                                    },
                                }} variant='outlined' onClick={() => handleApplyClick(job.recipientEmail, job.jobTitle)} className='gap-2'>
                                    Apply Now
                                </Button>
                            </div>
                        ))}
                    </div>
                </div>
            </main>

            <Footer />
        </div>
    );
};

export default Careers;
