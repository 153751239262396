import React from 'react';
import Sidebar from './SideBar';

const DashboardLayout = ({ children }) => {
  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content Area */}
      <div className="flex-grow p-6 bg-gray-100 overflow-y-auto">
        {children}
      </div>
    </div>
  );
};

export default DashboardLayout;
