export const communication_Data = {
    title: 'Communication Connection',
    subtitle: '',
    description:
        'Elevate your outreach with our comprehensive, automated database and email blast service, meticulously maintained and continually updated. Our platform empowers you to seamlessly send press releases, invitations, position papers, and more to a wide range of media outlets and legislators.',
    items: [
        { title: 'Comprehensive Media Reach', text: 'Target television, radio, newspapers, political websites, and blogs effortlessly.' },
        { title: 'Legislative and Government Relations', text: ' Access detailed email lists of legislators, staff, and committees, along with essential spreadsheets for custom mail merges, mailing labels, and contact lists.' },
        { title: 'State and Local Government Contacts', text: 'Connect with County Commissioners, Council Members, Judicial contacts, Press Secretaries, Legislative Liaisons, and Committee Executive Directors.' },
        { title: 'Specialized Congressional Data', text: 'Gain insights with district-specific Congressional data for precision-targeted communication.' },
    ],
    footer: 'Maximize your impact with a single, powerful tool designed for efficiency and effectiveness in today’s fast-paced communication landscape.',
}

export const PAGov2Go_Data = {
    title: 'PAGov2Go',
    subtitle: 'Your Real-Time Online Legislative Directory',
    description:
        'Stay connected and informed with PAGov2Go.com, the ultimate online legislative directory, optimized for easy access on the go.',
    items: [
        { title: 'Legislator Search', text: 'Find and connect with your representatives quickly and efficiently.' },
        { title: 'Executive Agency Search', text: 'Navigate the executive branch with ease.' },
        { title: 'Legislative Staff Search', text: 'Locate key staff members for informed communication.' },
        { title: 'House and Senate Marked Calendars', text: 'Stay updated with the latest legislative events and deadlines.' },
        { title: 'Committee Assignments', text: 'Access detailed committee assignments for both the House and Senate.' },
        { title: 'Live Streams', text: 'Watch House and Senate sessions live with direct links.' },
        { title: 'Committee Schedules', text: 'Never miss a meeting with up-to-date House and Senate committee schedules.' },
        { title: 'Legislator Birthdays', text: 'Keep track of legislator birthdays for a personal touch in your communications.' },
    ],
    footer: 'Experience the convenience of a real-time, always-updated legislative directory at your fingertips with PAGov2Go.com.',
}

