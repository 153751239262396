import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import UpCommingSeminars from './UpCommingSeminars/UpCommingSeminars';
import SeminarRegistration from './SeminarRegistration/SeminarRegistration';
import ReferenceMaterials from './ReferenceMaterials/ReferenceMaterials';

const Classroom = () => {
    const location = useLocation();
    const checkLocation = (path) => {
        return location.pathname.includes(path);
    };

    return (
        <div className="flex flex-col min-h-screen flex-grow overflow-y-auto bg-gray-100 ">
            <Header />
            {/* Use flex-grow to ensure the content takes up the available space */}
            <div className="flex-grow">
                {
                    checkLocation('up-coming-seminars') && <UpCommingSeminars />
                }
                {
                    checkLocation('seminar-registration') && <SeminarRegistration />
                }
                {
                    checkLocation('reference-materials') && <ReferenceMaterials />
                }
            </div>
            <Footer />
        </div>
    );
};

export default Classroom;
